import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Theme, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import { CSSProperties, ReactNode, useEffect, useState } from 'react'
import TextIconButton from './TextIconButton'

export default function DropDown(props: {
  title: string
  style?: CSSProperties
  children: ReactNode
  addMargin?: boolean
  startOpen?: boolean
}) {
  const [open, setOpen] = useState<boolean>(false)
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const toggleDropdown = () => {
    setOpen(!open)
  }

  useEffect(() => {
    if (props.startOpen) {
      setOpen(true)
    }
  }, [props.startOpen])

  const styles: {
    dropDownContainer: CSSProperties
    dropDownBox: CSSProperties
  } = {
    dropDownContainer: {
      display: 'inline-block',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: (props.addMargin || isSmallScreen) && open ? 12 : 0,
      ...props.style,
    },
    dropDownBox: {
      position: 'absolute',
      top: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '350px',
    },
  }
  return (
    <Box sx={styles.dropDownContainer}>
      <TextIconButton
        title={props.title}
        icon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={toggleDropdown}
        iconPosition={'right'}
      />
      {open && <Box sx={styles.dropDownBox}>{props.children}</Box>}
    </Box>
  )
}
