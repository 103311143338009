import { IconButton, styled } from '@mui/material'
import Style from '../constants/Style'
import Utils from '../utils/Utils'

export default function OutlinedIconButton(props: {
  children: React.ReactNode
  onClick?: () => void
}) {
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: Utils.hexToRGBA(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: Style.buttonBorderRadius,
  }))

  return (
    <StyledIconButton color="primary" onClick={props.onClick}>
      {props.children}
    </StyledIconButton>
  )
}
