import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { CSSProperties, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { UserError } from '../api/apiTypes'
import { ReactComponent as LogoInfomindsDark } from '../assets/images/LogoInfomindsDark.svg'
import { ReactComponent as LogoInfomindsLight } from '../assets/images/LogoInfomindsLight.svg'
import LoadingSpinner from '../components/LoadingSpinner'
import OutlinedButton from '../components/OutlinedButton'
import Page from '../components/Page'
import Style from '../constants/Style'
import { useAuthentication } from '../contexts/AuthenticationContext'
import { useColorTheme } from '../contexts/ThemeContext'

export default function LoginPage() {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [customerCodeInvalid, setCustomerCodeInvalid] = useState<boolean>(false)
  const [usernameInvalid, setUsernameInvalid] = useState<boolean>(false)
  const [passwordInvalid, setPasswordInvalid] = useState<boolean>(false)
  const { isDarkMode } = useColorTheme()
  const { login, loadingLogin } = useAuthentication()
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()

  function handleDemoLogin() {
    setLoading(true)
    navigate('/home')
    setLoading(false)
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    // reset errors for new input
    resetErrors()

    // get data from inputs
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const customerCode = data.get('customerCode')?.toString().trim()
    const username = data.get('username')?.toString().trim()
    const password = data.get('password')?.toString().trim()

    // validate login data and set errors if needed
    if (!customerCode || !username || !password) {
      setError(t('PLEASE_FILL_IN_ALL_FIELDS'))

      if (!customerCode) setCustomerCodeInvalid(true)
      if (!username) setUsernameInvalid(true)
      if (!password) setPasswordInvalid(true)

      return
    }

    setLoading(true)

    // call login function
    login({ customerCode, username, password })
      .then((_result) => navigate('/home'))
      .catch((error: UserError) => {
        setError(`${t('LOGIN_ERROR')}${error.showToUser ? ': ' + error.message : ''}`)
      })
      .finally(() => setLoading(false))
  }

  function resetErrors() {
    setError(null)
    setCustomerCodeInvalid(false)
    setUsernameInvalid(false)
    setPasswordInvalid(false)
  }

  const styles: {
    container: CSSProperties
    marginTop: CSSProperties
    textInput: CSSProperties
    error: CSSProperties
  } = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    marginTop: {
      marginTop: Style.margin,
    },
    textInput: {
      width: Style.loginFieldWidth,
      borderRadius: Style.buttonBorderRadius,
    },
    error: {
      border: `solid 1px ${theme.palette.error.main}`,
      borderRadius: Style.buttonBorderRadius,
      padding: Style.padding,
      width: 316,
    },
  }

  return (
    <Page disableLogin>
      <Box sx={styles.container}>
        {isDarkMode ? <LogoInfomindsDark /> : <LogoInfomindsLight />}
        {error && (
          <Box sx={[styles.error, styles.marginTop]}>
            <Typography color="error" align="center">
              {error}
            </Typography>
          </Box>
        )}
        <Box component="form" onSubmit={handleSubmit} sx={[styles.container, styles.marginTop]}>
          <TextField
            margin="dense"
            id="customerCode"
            label={t('CUSTOMER_CODE')}
            name="customerCode"
            autoComplete="customer-code"
            error={customerCodeInvalid}
            InputProps={{ sx: styles.textInput }}
          />
          <Box sx={[styles.container, styles.marginTop]}>
            <TextField
              margin="dense"
              id="username"
              label={t('USERNAME')}
              name="username"
              autoComplete="username"
              error={usernameInvalid}
              InputProps={{ sx: styles.textInput }}
            />
            <TextField
              margin="dense"
              name="password"
              label={t('PASSWORD')}
              type="password"
              id="password"
              autoComplete="current-password"
              error={passwordInvalid}
              InputProps={{ sx: styles.textInput }}
            />
          </Box>
          <OutlinedButton type="submit" style={styles.marginTop} width={Style.loginFieldWidth}>
            {t('LOGIN')}
          </OutlinedButton>
          <OutlinedButton
            onClick={handleDemoLogin}
            style={styles.marginTop}
            width={Style.loginFieldWidth}>
            {t('LOGIN_AS_GUEST')}
          </OutlinedButton>
        </Box>
      </Box>
      <LoadingSpinner loading={loading || loadingLogin} />
    </Page>
  )
}
