import { Button, styled } from '@mui/material'
import { CSSProperties } from 'react'
import Style from '../constants/Style'
import Utils from '../utils/Utils'

export default function OutlinedButton(props: {
  children: React.ReactNode
  onClick?: () => void
  width?: number
  maxWidth?: number
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  style?: CSSProperties & any
  type?: 'button' | 'submit' | 'reset' | undefined
}) {
  const StyledButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1),
    width: props.width ?? 'auto',
    maxWidth: props.maxWidth ?? '100%',
    backgroundColor: Utils.hexToRGBA(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderRadius: Style.buttonBorderRadius,
  }))

  return (
    <StyledButton
      type={props.type ?? 'button'}
      variant="outlined"
      onClick={props.onClick}
      endIcon={props.endIcon}
      startIcon={props.startIcon}
      sx={props.style}>
      {props.children}
    </StyledButton>
  )
}
