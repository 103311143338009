import { ERPType } from '../types'

export interface RequestOptions {
  method: 'POST' | 'PATCH' | 'GET' | 'DELETE'
  headers: Headers
  body?: string
}

export interface ApiData {
  token?: string
}

export interface AppInfoType {
  softwareCode: string
  app: string
  icon: string
  note?: string
  erp: ERPType[]
  supportedPlatforms: SupportedType[]
  authentication: string[]
  supportedDevices?: DeviceType[]
}

export interface SupportedType {
  platform: AllPlatformType
  minVersion: number | string | undefined
  link?: string
}

export interface DeviceType {
  brand: string
  models: string[]
  installationNotes?: string[]
}

export enum AllPlatformType {
  android = 'android',
  iOS = 'iOS',
  windows = 'windows',
  web = 'web',
}

export interface AppLicenseRequest {
  customerCode: string
  user: string
  password: string
  language?: string
}

export interface ApiError {
  Message: string
  Code: number
  InnerException: any
  StackTrace: any
}

export class UserError extends Error {
  showToUser: boolean

  constructor(message: string, showToUser: boolean) {
    super(message)
    this.showToUser = showToUser
    this.name = 'UserError'
  }
}
