import { List, Stack, Theme, useMediaQuery, useTheme } from '@mui/material'
import { CSSProperties, useEffect, useState } from 'react'
import api from '../api/api'
import LoadingSpinner from '../components/LoadingSpinner'
import Page from '../components/Page'
import Website from '../components/Website'
import { useAuthentication } from '../contexts/AuthenticationContext'
import { WebsiteInfo } from '../types'
import Utils from '../utils/Utils'

export default function HomePage() {
  const [loading, setLoading] = useState<boolean>(false)
  const [websites, setWebsites] = useState<WebsiteInfo[]>([])
  const [displayWebsites, setDisplayWebsites] = useState<WebsiteInfo[]>([])
  const [licenseWidth, setLicenseWidth] = useState<number>(0)
  const { loadingLogin, isLoggedIn, licenses } = useAuthentication()
  const theme = useTheme()

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const listDirection = isSmallScreen ? 'column' : 'row'

  useEffect(() => {
    setLoading(true)
    api
      .getWebsitesInfo()
      .then((apiResult: WebsiteInfo[]) => setWebsites(apiResult))
      .catch(console.error)
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      const filteredWebsites = Utils.filterWebsitesERP(websites, licenses)
      setDisplayWebsites(filteredWebsites)
    } else {
      setDisplayWebsites(websites)
    }
  }, [websites, isLoggedIn, licenses])

  useEffect(() => {
    setLicenseWidth(Utils.getLicenseLength(displayWebsites, licenses, theme))
  }, [displayWebsites, licenses, theme])

  useEffect(() => {}, [])

  const styles: {
    websitesBase: CSSProperties
    websitesRow: CSSProperties
    websitesColumn: CSSProperties
  } = {
    websitesBase: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    websitesRow: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    websitesColumn: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: 2,
      marginTop: 8,
    },
  }

  return (
    <Page>
      <List
        component={Stack}
        direction={listDirection}
        sx={[
          styles.websitesBase,
          listDirection === 'row' ? styles.websitesRow : styles.websitesColumn,
        ]}>
        {displayWebsites.map((website, idx) => (
          <Website key={`${website.name}_${idx}`} website={website} licenseWidth={licenseWidth} />
        ))}
      </List>
      <LoadingSpinner loading={loading || loadingLogin} />
    </Page>
  )
}
