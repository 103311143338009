import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// import translation .json files
import de from './assets/languages/de.json'
import en from './assets/languages/en.json'
import it from './assets/languages/it.json'

// initialize and configure i18next with the language detector and the imported translation files
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // set the fallback language to english
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // set the resources for the translation to the imported translation files
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
      it: {
        translation: it,
      },
    },
  })

export default i18n
