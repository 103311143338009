import { Alert, AlertColor, AlertTitle, Snackbar } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export default function AlertSnackbar(props: {
  open: boolean
  setOpen: { (b: boolean): void }
  message: string
  severity: AlertColor
}) {
  const { t } = useTranslation()
  function handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === 'clickaway') {
      return
    }
    props.setOpen(false)
  }

  const alertTitle = useMemo(
    () =>
      props.severity === 'success'
        ? t('LOGIN_SUCCESS')
        : props.severity === 'error'
        ? t('LOGIN_ERROR')
        : '',
    [props.severity, t],
  )

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={props.open}
      autoHideDuration={3000}
      onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={props.severity}
        sx={{
          width: '100%',
        }}>
        <AlertTitle>{`${alertTitle}!`}</AlertTitle>
        {props.message}
      </Alert>
    </Snackbar>
  )
}
