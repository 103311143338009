import Box from '@mui/material/Box'
import { CSSProperties, ReactNode } from 'react'
import Style from '../constants/Style'
import Header from './Header'

export default function Page(props: { children: ReactNode; disableLogin?: boolean }) {
  const styles: { pageContainer: CSSProperties; header: CSSProperties } = {
    pageContainer: {
      padding: Style.padding,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      zIndex: 1,
    },
    header: {
      display: 'flex',
      width: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1,
    },
  }
  return (
    <Box sx={styles.pageContainer}>
      <Box sx={styles.header}>
        <Header disableLogin={props.disableLogin} />
      </Box>
      {props.children}
    </Box>
  )
}
