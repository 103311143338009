import CloseIcon from '@mui/icons-material/Close'
import OpenLinkIcon from '@mui/icons-material/OpenInNewOutlined'
import QrCodeIcon from '@mui/icons-material/QrCode2Outlined'
import { Box, Button, IconButton, Modal, Typography, useTheme } from '@mui/material'
import i18next from 'i18next'
import { CSSProperties, useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from '../api/api'
import Style from '../constants/Style'
import { useAuthentication } from '../contexts/AuthenticationContext'
import Utils from '../utils/Utils'
import LinkText from './LinkText'
import LoadingSpinner from './LoadingSpinner'
import OutlinedIconButton from './OutlinedIconButton'

export default function LicensePopup(props: { license: string; url?: string }) {
  const [open, setOpen] = useState<boolean>(false)
  const [imageSrc, setImageSrc] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const { getLoginUrl } = useAuthentication()
  const theme = useTheme()

  const handleClick = async () => {
    setLoading(true)
    setOpen(true)
    api
      .getLicenseQRImage(props.license, i18next.language)
      .then((response) => {
        const image = Utils.convertStringToImage(response)
        setImageSrc(image)
      })
      .catch((error) => {
        console.error('Error while fetching License QR code:', error)
        setOpen(false)
      })
      .finally(() => setLoading(false))
  }

  const handleClose = () => {
    setOpen(false)
    setImageSrc('')
  }

  const styles: {
    imageBox: CSSProperties & any
    closeButton: CSSProperties
  } = {
    imageBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 1,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      padding: 2,
      color: 'text.primary',
      maxWidth: '90vw',
      maxHeight: '90vh',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: '8px',
    },
  }

  return (
    <Box>
      <OutlinedIconButton onClick={handleClick}>
        <QrCodeIcon />
      </OutlinedIconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-popup-title"
        aria-describedby="image-popup-description">
        <Box sx={styles.imageBox}>
          {loading ? (
            <LoadingSpinner loading={loading} />
          ) : (
            <>
              <IconButton style={styles.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>

              {imageSrc && (
                <img src={imageSrc} alt={props.license} style={{ width: '60%', height: 'auto' }} />
              )}
              <Typography fontSize={Style.fontSizeSmall} textAlign={'center'}>
                {props.license}
              </Typography>
              <LinkText href={getLoginUrl(props.url, props.license)}>
                <Button
                  variant="contained"
                  endIcon={<OpenLinkIcon />}
                  sx={{ padding: theme.spacing(1) }}>
                  {t('OPEN_LINK')}
                </Button>
              </LinkText>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  )
}
