import { AppLicense, Credentials } from '../types'
import { ApiUtils } from '../utils/ApiUtils'
import { ApiData, AppInfoType, AppLicenseRequest, RequestOptions, UserError } from './apiTypes'

//API URLS and Keys
const appRequirementsUrl =
  'https://mobileupdate.infominds.eu/api/PublicFiles?requestedFileName=requirements.json'
const iosApiUrl = 'https://testrxios.infominds.eu/im.Ios.Api.sc/api' //'https://rxios.infominds.eu/im.Ios.Api.sc/api'
const ApiKey = 'centralizedStaticWebApp-7341c030-7a73-4611-842d-636e52f5645c'

const apiData: ApiData = {
  token: undefined,
}

const api = {
  iosApiLogin: async () => {
    const requestBody = {
      apiKey: ApiKey,
    }
    const requestOptions = ApiUtils.getRequestOptions('POST', requestBody)
    const result = await makeApiRequest(`${iosApiUrl}/authenticate/login/apiKey`, requestOptions)
    const tmpResult = result as { token: string }
    apiData.token = `Bearer ${tmpResult.token}`
    return `Bearer ${tmpResult.token}`
  },
  getWebsitesInfo: async () => {
    const requestOptions = ApiUtils.getRequestOptions('GET')
    const result = await makeApiRequest(appRequirementsUrl, requestOptions)
    const appInfoResult = result as AppInfoType[]
    return ApiUtils.getWebsitesFromApiResult(appInfoResult)
  },
  getAppLicenses: async (credentials: Credentials, language: string) => {
    const requestBody: AppLicenseRequest = {
      customerCode: credentials.customerCode,
      user: credentials.username,
      password: credentials.password,
      language: language,
    }
    const requestOptions = ApiUtils.getRequestOptions('POST', requestBody)
    const result = await makeIosApiRequest(`${iosApiUrl}/customer/applicenses`, requestOptions)
    return result as AppLicense
  },
  getLicenseQRImage: async (licenseId: string, language: string) => {
    const license = ApiUtils.formatLicenseForApi(licenseId)
    const requestOptions = ApiUtils.getRequestOptions('GET')
    const result = await makeIosApiRequest(
      `${iosApiUrl}/qRCode?LicenseId=${license}&Language=${language}&ResultType=Png`,
      requestOptions,
    )
    return result as string
  },
}

async function makeIosApiRequest(url: string, requestOptions: RequestOptions) {
  if (!apiData.token) {
    return await api.iosApiLogin().then((token) => {
      requestOptions.headers.append('Authorization', token)
      return makeApiRequest(url, requestOptions)
    })
  } else {
    requestOptions.headers.append('Authorization', apiData.token)
    return makeApiRequest(url, requestOptions)
  }
}

async function makeApiRequest(url: string, requestOptions: RequestOptions) {
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 500) {
            // Parse the response JSON and set error to response.Message
            const json = await response.json()
            throw new UserError(
              json.Message || `${response.status} - ${response.statusText}`,
              !!json.Message,
            )
          } else {
            throw new UserError(`${response.status} - ${response.statusText}`, false)
          }
        }
        return response.json()
      })
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default api
