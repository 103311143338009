import { Theme } from '@mui/material'
import { LicenseDetails, WebsiteInfo } from '../types'

const Utils = {
  /**
   * Returns the first two letters of the username in upper case
   * @param username the username to get the avatar letters from
   * @returns the first two letters of the username for the avatar
   */
  getAvatarLetters(username: string) {
    return username.trim().slice(0, 2).toUpperCase()
  },
  /**
   * Creates a Hello message to the user with a greating depending on which time it is in this form
   * @param username the user to greet
   * @param t the translation function to use the users language
   * @returns The appropriate users language
   */
  createHelloMessage(username: string | undefined, t: any) {
    const currentDate = new Date()
    const currentHour = currentDate.getHours()
    let greeting = ''

    if (currentHour >= 5 && currentHour < 13) {
      greeting = t('GOOD_MORNING')
    } else if (currentHour >= 13 && currentHour < 18) {
      greeting = t('GOOD_AFTERNOON')
    } else if (currentHour >= 18 && currentHour < 24) {
      greeting = t('GOOD_EVENING')
    } else {
      greeting = t('GOOD_NIGHT')
    }

    return username ? `${greeting} ${username}` : greeting
  },

  /**
   * converts a base 64 encoded image string to an image uri
   * @param base64image the base 64 encoded image string
   * @returns the image uri
   */
  convertStringToImage(base64image: string) {
    //decode Base64 string into binary string
    const binaryString = atob(base64image)

    //convert binary string to array buffer
    const arrayBuffer = new ArrayBuffer(binaryString.length)
    const uint8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i)
    }

    //create Blob object
    const blob = new Blob([uint8Array], { type: 'image/png' })

    //create data URL
    return URL.createObjectURL(blob)
  },

  /**
   * Filters websites  based on which licenses are active
   * @param websites the websites to filter
   * @param licenses the active licenses
   * @returns a list of websites that have an active license
   */
  filterWebsitesERP(websites: WebsiteInfo[], licenses: LicenseDetails[]) {
    // get the app codes of the active licensed apps
    const licenseApps = new Set(licenses.map((lic) => lic.softwareCode))
    return websites.filter((web) => licenseApps.has(web.softwareCode))
  },

  /**
   * gets the longest license text length
   * @param websites the available websites
   * @param licenses the available licenses
   * @returns the length of the longest license text
   */
  getLongestLicenseText(websites: WebsiteInfo[], licenses: LicenseDetails[]) {
    const licenseList = websites.reduce((acc, curr) => {
      const appLicenses = licenses.filter((lic) => lic.softwareCode === curr.softwareCode)
      return acc.concat(appLicenses.map((lic) => lic.appLicenseId))
    }, [] as string[])

    const longest = licenseList.reduce((max, curr) => Math.max(max, curr.length), 0)
    licenseList.forEach((lic) => {
      if (lic.length === longest) {
        console.log('longest:', lic, 'length:', longest)
      }
    })

    return longest
  },

  /**
   * Calculates the width of the button based on the max license text length
   * @param maxLicenseTextLength the max license text length
   * @param theme the theme to use for the calculation
   * @returns the width of the button
   */
  calculateButtonWidth(maxLicenseTextLength: number, theme: Theme): number {
    return theme.breakpoints.down('md')
      ? maxLicenseTextLength * theme.typography.fontSize * 0.85
      : maxLicenseTextLength * theme.typography.fontSize * 1.1
  },

  /**
   * calculates the width of the license button based on the longest license text
   * @param websites the available websites
   * @param licenses the available licenses
   * @param theme the theme used for the fontSize calculation
   * @returns the width of the license button as a number
   */
  getLicenseLength(websites: WebsiteInfo[], licenses: LicenseDetails[], theme: Theme): number {
    const longest = this.getLongestLicenseText(websites, licenses)
    return this.calculateButtonWidth(longest, theme)
  },

  /**
   * Converts a hex color to an rgba color with the given opacity
   * @param hex the hex color to convert
   * @param opacity the opacity to use
   * @returns the rgba color with the given opacity
   */
  hexToRGBA(hex: string, opacity: number): string {
    const hexValue = hex.replace('#', '')
    const r = parseInt(hexValue.substring(0, 2), 16)
    const g = parseInt(hexValue.substring(2, 4), 16)
    const b = parseInt(hexValue.substring(4, 6), 16)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  },
}
export default Utils
