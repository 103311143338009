import OpenLinkIcon from '@mui/icons-material/OpenInNewOutlined'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import BlurBackground from '../assets/images/BlurBackground.png'
import Style from '../constants/Style'
import { useAuthentication } from '../contexts/AuthenticationContext'
import { useColorTheme } from '../contexts/ThemeContext'
import { PlatformType, WebsiteInfo } from '../types'
import DropDown from './DropDown'
import LicensePopup from './LicensePopup'
import LinkText from './LinkText'
import OutlinedButton from './OutlinedButton'

export default function Website(props: { website: WebsiteInfo; licenseWidth: number }) {
  const { isDarkMode, colorMode } = useColorTheme()
  const { isLoggedIn, getLicenses, getLoginUrl } = useAuthentication()
  const { t } = useTranslation()

  const licenses = getLicenses(props.website.softwareCode)
  const showLicense = isLoggedIn && licenses.length > 0

  const styles: {
    container: CSSProperties
    coreInfo: CSSProperties
    websiteIcon: CSSProperties
    stores: CSSProperties
    storeIcon: CSSProperties
    license: CSSProperties
    blurBackground: CSSProperties
    blurBackgroundContainer: CSSProperties
  } = {
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 3,
    },
    coreInfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 1,
    },
    websiteIcon: {
      height: 120,
      width: 120,
    },
    stores: {
      marginTop: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 0.5,
    },
    storeIcon: {
      height: 30,
      width: 100,
    },
    license: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 1,
    },
    blurBackground: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 220,
      width: 220,
      zIndex: -1,
    },
    blurBackgroundContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 3,
    },
  }

  return (
    <Box sx={styles.container}>
      <LinkText
        href={
          isLoggedIn && licenses.length === 1
            ? getLoginUrl(props.website.link, licenses[0])
            : props.website.link
        }>
        <Box sx={styles.coreInfo}>
          <Box sx={styles.blurBackgroundContainer}>
            <img
              src={props.website.icon}
              style={styles.websiteIcon}
              alt={`${props.website.name} Icon`}
            />
            <img src={BlurBackground} alt="Blur Background" style={styles.blurBackground} />
          </Box>

          <Typography
            fontSize={Style.fontSizeSmall}
            textAlign={'center'}
            color={(theme) => theme.palette.primary.dark}>
            {props.website.name}
          </Typography>
          {!showLicense && (
            <OutlinedButton endIcon={<OpenLinkIcon />}>{t('OPEN_LINK')}</OutlinedButton>
          )}
        </Box>
      </LinkText>
      {showLicense && (
        <DropDown title={t('LICENSES')} addMargin startOpen>
          <Box sx={[styles.coreInfo, { marginTop: 1 }]}>
            {licenses.map((lic) => (
              <Box key={lic} sx={styles.license}>
                <LinkText href={getLoginUrl(props.website.link, lic)}>
                  <OutlinedButton
                    endIcon={<OpenLinkIcon />}
                    width={props.licenseWidth}
                    maxWidth={Style.maxButtonWidth}>
                    {lic}
                  </OutlinedButton>
                </LinkText>
                <LicensePopup license={lic} url={props.website.link} />
              </Box>
            ))}
          </Box>
        </DropDown>
      )}
      {props.website.otherPlatforms.length > 0 && (
        <DropDown title={t('DOWNLOAD_APP')}>
          <Box sx={styles.stores}>
            {props.website.otherPlatforms.map((otherPlatform) => {
              const store =
                otherPlatform.platform === PlatformType.android ? 'GooglePlay' : 'AppStore'
              const imagePath = `./images/${store}_${colorMode}.png`

              return (
                <LinkText
                  style={Style.iconButtonStyle(isDarkMode)}
                  key={otherPlatform.link}
                  href={otherPlatform.link}>
                  <img style={styles.storeIcon} src={imagePath} alt={store} />
                </LinkText>
              )
            })}
          </Box>
        </DropDown>
      )}
    </Box>
  )
}
