import Box from '@mui/material/Box'
import { CSSProperties } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './App.css'
import { ReactComponent as InfomindsTreeImage } from './assets/images/infomindsTree.svg'
import AuthenticationProvider from './contexts/AuthenticationContext'
import ThemeProvider from './contexts/ThemeContext'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'

export default function App() {
  const styles: {
    background: CSSProperties & any
    root: CSSProperties
    logo: CSSProperties
  } = {
    background: {
      bgcolor: 'background.default',
      color: 'text.primary',
    },
    root: {
      minHeight: '100vh',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    logo: {
      position: 'fixed',
      bottom: 0,
      right: 0,
      zIndex: 0,
    },
  }

  return (
    <ThemeProvider>
      <Box sx={styles.background} className="main-container">
        <Box sx={styles.root}>
          <BrowserRouter>
            <AuthenticationProvider>
              <Routes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/home" element={<HomePage />} />
              </Routes>
            </AuthenticationProvider>
          </BrowserRouter>
          <Box sx={styles.logo}>
            <InfomindsTreeImage />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
