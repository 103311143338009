import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import LoginIcon from '@mui/icons-material/LoginOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import i18next from 'i18next'
import { CSSProperties, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Style from '../constants/Style'
import { useAuthentication } from '../contexts/AuthenticationContext'
import { useColorTheme } from '../contexts/ThemeContext'
import Utils from '../utils/Utils'
import LoadingSpinner from './LoadingSpinner'
import TextIconButton from './TextIconButton'
import UserAvatar from './UserAvatar'

export default function Header(props: { disableLogin?: boolean }) {
  const [loading, setLoading] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [title, setTitle] = useState('')
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const { isDarkMode, toggleColorMode } = useColorTheme()
  const { isLoggedIn, logout, username, customerName } = useAuthentication()
  const { t } = useTranslation()

  const [currentLanguage, setCurrentLanguage] = useState<string>(i18next.language)

  const languages = [
    { code: 'en', label: t('ENGLISH') },
    { code: 'de', label: t('GERMAN') },
    { code: 'it', label: t('ITALIAN') },
  ]

  function handleLanguageChange(event: SelectChangeEvent) {
    const newLang = event.target.value
    setCurrentLanguage(newLang)
    i18next.changeLanguage(newLang)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function onLoginPress() {
    navigate('/login')
  }

  function onLogoutPress() {
    setLoading(true)
    logout().finally(() => {
      handleClose()
      navigate('/login')
      setLoading(false)
    })
  }

  useEffect(() => {
    const updateTitle = () => {
      if (isLoggedIn) {
        setTitle(Utils.createHelloMessage(username, t))
      } else {
        setTitle(t('INFOMINDS_APPS'))
      }
    }

    updateTitle() // initial call to set the title

    const now = new Date()
    const msUntilNextHour = (60 - now.getMinutes()) * 60000 - now.getSeconds() * 1000

    // set a timeout to update the title at the start of the next hour
    const timeoutId = setTimeout(() => {
      updateTitle()

      // set an interval to update the title every hour
      const intervalId = setInterval(updateTitle, 3600000) // 3600000 ms = 1 hour

      // clear the interval when the component unmounts
      return () => clearInterval(intervalId)
    }, msUntilNextHour)

    // cleanup timeout and interval on component unmount
    return () => clearTimeout(timeoutId)
  }, [isLoggedIn, username, t])

  const styles: {
    headerLine: CSSProperties
    settingsLogin: CSSProperties
    menuItem: CSSProperties
    select: CSSProperties & any
  } = {
    headerLine: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100vw',
      paddingTop: Style.padding,
      paddingLeft: Style.padding,
      paddingRight: Style.padding,
      top: 0,
    },
    settingsLogin: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 1,
    },
    menuItem: {
      minWidth: '180px',
    },
    select: {
      borderRadius: Style.buttonBorderRadius,
    },
  }

  return (
    <Box sx={styles.headerLine}>
      <Typography fontSize={Style.fontSizeLarge}>{title}</Typography>
      <Box sx={styles.settingsLogin}>
        {!props.disableLogin && !isLoggedIn && (
          <TextIconButton
            title={t('LOGIN')}
            icon={<LoginIcon />}
            onClick={onLoginPress}
            iconPosition={'left'}
            textFontSize={Style.fontSizeMedium}
          />
        )}
        {isLoggedIn && username ? (
          <UserAvatar username={username} onClick={handleClick} />
        ) : (
          <IconButton sx={Style.iconButtonStyle(isDarkMode)} onClick={handleClick}>
            <SettingsIcon />
          </IconButton>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          mt: 1,
        }}>
        {isLoggedIn && (
          <Box>
            <MenuItem sx={styles.menuItem}>
              <Typography>{`${t('CUSTOMER_NAME')}:`}</Typography>
              <Typography sx={{ ml: 1 }}>{customerName}</Typography>
            </MenuItem>
            <MenuItem sx={styles.menuItem}>
              <Typography>{`${t('USERNAME')}:`}</Typography>
              <Typography sx={{ ml: 1 }}>{username}</Typography>
            </MenuItem>
            <Divider />
          </Box>
        )}
        <MenuItem sx={styles.menuItem}>
          <FormControl fullWidth margin="dense" size="small">
            <InputLabel id="language-select-label">{t('LANGUAGE')}</InputLabel>
            <Select
              labelId="language-select-label"
              id="language-select"
              value={currentLanguage}
              label={t('LANGUAGE')}
              onChange={handleLanguageChange}
              sx={styles.select}>
              {languages.map((lang) => (
                <MenuItem key={lang.code} value={lang.code}>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MenuItem>
        <MenuItem sx={styles.menuItem} onClick={toggleColorMode}>
          <ListItemIcon>{isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}</ListItemIcon>
          <Typography variant="inherit">{isDarkMode ? t('LIGHT_MODE') : t('DARK_MODE')}</Typography>
        </MenuItem>

        {isLoggedIn && (
          <MenuItem sx={styles.menuItem} onClick={onLogoutPress}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('LOGOUT')}</Typography>
          </MenuItem>
        )}
      </Menu>
      <LoadingSpinner loading={loading} />
    </Box>
  )
}
