import { CSSProperties } from 'react'

const Style = {
  padding: 2,
  borderRadius: 3,
  buttonBorderRadius: 25,
  maxButtonWidth: 350,
  loginFieldWidth: 350,
  margin: 2,
  fontSizeLarge: '30px',
  fontSizeMedium: '20px',
  fontSizeSmall: '18px',
  iconButtonStyle: (isDarkMode: boolean): CSSProperties & any => {
    return {
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.10)' : 'rgba(0, 0, 0, 0.10)',
      },
    }
  },
}

export default Style
