import { Link } from '@mui/material'
import { CSSProperties, ReactNode } from 'react'

export default function LinkText(props: {
  href?: string
  style?: CSSProperties & any
  children: ReactNode
}) {
  return (
    <Link
      sx={{ ...props.style }}
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      underline="none">
      {props.children}
    </Link>
  )
}
