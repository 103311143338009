export interface WebsiteInfo {
  softwareCode: string
  name: string
  icon: string
  erp: ERPType[]
  link?: string
  otherPlatforms: OtherPlatform[]
}

export interface OtherPlatform {
  platform: PlatformType
  link?: string
}

export enum ERPType {
  ergo = 'ergo',
  radix = 'radix',
}

export enum PlatformType {
  android = 'android',
  iOS = 'iOS',
}

export interface AppLicense {
  customerCode: string
  customerName: string
  customerAppLicenseDetails: LicenseDetails[]
}

export interface LicenseDetails {
  appLicenseId: string
  softwareCode: string
  software: string
  moduleCode: string
  loginType: string
  isDemo: boolean
  expiration: Date
}

export interface Credentials {
  customerCode: string
  username: string
  password: string
}
