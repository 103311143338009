import { useTheme } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import { CSSProperties } from 'react'
import Utils from '../utils/Utils'

export default function UserAvatar(props: {
  username: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}) {
  const theme = useTheme()

  const styles: { iconButton: CSSProperties; avatar: CSSProperties & any } = {
    iconButton: {
      width: 40,
      height: 40,
    },
    avatar: {
      bgcolor: theme.palette.primary.main,
    },
  }

  return (
    <IconButton onClick={props.onClick} sx={styles.iconButton}>
      <Avatar sx={styles.avatar}>{Utils.getAvatarLetters(props.username)}</Avatar>
    </IconButton>
  )
}
