import { AllPlatformType, AppInfoType, RequestOptions, SupportedType } from '../api/apiTypes'
import { OtherPlatform, PlatformType, WebsiteInfo } from '../types'

const getHeaders = (auth?: string) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  if (auth) {
    myHeaders.append('Authorization', auth)
  }
  return myHeaders
}

export const ApiUtils = {
  getHeaders: getHeaders,
  /**
   * Returns the request options for the given request method and request body
   * @param requestMethod the request method: POST, PATCH, GET, DELETE
   * @param requestBody the request body
   * @returns the request options
   */
  getRequestOptions: (
    requestMethod: 'POST' | 'PATCH' | 'GET' | 'DELETE',
    requestBody?: any,
  ): RequestOptions => {
    const requestHeaders = getHeaders()
    if (requestBody) {
      const jsonRequestBody = JSON.stringify(requestBody)
      return {
        method: requestMethod,
        headers: requestHeaders,
        body: jsonRequestBody,
      }
    } else
      return {
        method: requestMethod,
        headers: requestHeaders,
      }
  },
  /**
   * maps the api result as AppInfoType[] to the website info as WebsiteInfo[]
   * @param apiResult the result from the api
   * @returns the websites
   */
  getWebsitesFromApiResult(apiResult: AppInfoType[]): WebsiteInfo[] {
    const websites = apiResult.filter((app: AppInfoType) =>
      app.supportedPlatforms.find(
        (supportedPlatform: SupportedType) => supportedPlatform.platform === 'web',
      ),
    )

    return websites.map((website) => {
      const webLink = website.supportedPlatforms.filter(
        (supportedPlatform: SupportedType) => supportedPlatform.platform === 'web',
      )[0].link
      const otherPlatforms: OtherPlatform[] = website.supportedPlatforms
        .filter(
          (supportedPlatform: SupportedType) =>
            supportedPlatform.platform !== 'web' && supportedPlatform.platform !== 'windows',
        )
        .map((supportedPlatform: SupportedType) => {
          return {
            platform:
              supportedPlatform.platform === AllPlatformType.android
                ? PlatformType.android
                : PlatformType.iOS,
            link: supportedPlatform.link,
          }
        })
      return {
        softwareCode: website.softwareCode,
        name: website.app,
        icon: website.icon,
        erp: website.erp,
        link: webLink,
        otherPlatforms: otherPlatforms ?? [],
      }
    })
  },
  /**
   * Formats the license string for the api url to be treated correctly by http requests
   * @param license the license string to format
   * @returns the formatted license string
   */
  formatLicenseForApi(license: string) {
    return encodeURIComponent(license)
  },
}
