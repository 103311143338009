import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material'
import { ReactNode, createContext, useContext, useMemo, useState } from 'react'

export type ThemeContextType = {
  isDarkMode: boolean
  colorMode: 'light' | 'dark'
  toggleColorMode: () => void
}

export const ThemeContext = createContext<ThemeContextType>({
  isDarkMode: false,
  colorMode: 'light',
  toggleColorMode: () => {},
})

export default function ThemeProvider(props: { children: ReactNode }) {
  const [colorMode, setColorMode] = useState<'light' | 'dark'>(
    getBrowserColorScheme() ? 'dark' : 'light',
  )
  const isDarkMode = colorMode === 'dark'

  /**
   * Gets the color scheme of the browser
   * @returns true if the browser is in dark mode, false otherwise
   */
  function getBrowserColorScheme() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
  }

  /**
   * Toggles the color mode between light and dark
   */
  function toggleColorMode() {
    setColorMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
  }

  // creates the theme based on the current ColorMode and the given override values
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colorMode,
          ...(colorMode === 'light'
            ? {
                //override light mode values
                primary: {
                  main: '#26D07C',
                  dark: '#008B73',
                },
                background: {
                  default: '#FFFFFF',
                },
              }
            : {
                //override dark mode values
                primary: {
                  main: '#26D07C',
                  dark: '#008B73',
                },
                background: {
                  default: '#0F0F0F',
                  paper: '#2E2E2E',
                },
              }),
        },
        typography: {
          fontFamily: 'Roboto, Arial, sans-serif',
        },
      }),
    [colorMode],
  )

  return (
    <ThemeContext.Provider value={{ isDarkMode, colorMode, toggleColorMode }}>
      <MUIThemeProvider theme={theme}>{props.children}</MUIThemeProvider>
    </ThemeContext.Provider>
  )
}

export function useColorTheme() {
  const context = useContext(ThemeContext)

  if (context === undefined) throw new Error('useColorTheme can only be used inside ThemeProvider')
  return context
}
