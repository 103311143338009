import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default function LoadingSpinner(props: { loading: boolean }) {
  return (
    <Backdrop
      sx={{
        color: (theme) => theme.palette.primary.main,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={props.loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
