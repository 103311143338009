import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CSSProperties, ReactNode } from 'react'
import Style from '../constants/Style'
import { useColorTheme } from '../contexts/ThemeContext'

export default function TextIconButton(props: {
  title: string
  icon: ReactNode
  onClick: () => void
  iconPosition: 'left' | 'right'
  textFontSize?: string
}) {
  const { isDarkMode } = useColorTheme()

  const styles: { container: CSSProperties & any } = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      gap: 1,
      padding: 0.5,
      paddingLeft: props.iconPosition === 'right' ? 1 : 0.5,
      paddingRight: props.iconPosition === 'left' ? 1 : 0.5,
      borderRadius: Style.borderRadius,
      ...Style.iconButtonStyle(isDarkMode),
    },
  }

  return (
    <Box sx={styles.container} onClick={props.onClick}>
      {props.iconPosition === 'left' && <>{props.icon}</>}
      <Typography fontSize={props.textFontSize ?? Style.fontSizeSmall}>{props.title}</Typography>
      {props.iconPosition === 'right' && <>{props.icon}</>}
    </Box>
  )
}
