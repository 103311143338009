import { AlertColor } from '@mui/material/Alert'
import i18next from 'i18next'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import api from '../api/api'
import AlertSnackbar from '../components/AlertSnackbar'
import { LocalStorageKeys } from '../constants/Constants'
import { Credentials, LicenseDetails } from '../types'
import Utils from '../utils/Utils'

export type AuthenticationContextType = {
  isLoggedIn: boolean
  licenses: LicenseDetails[]
  loadingLogin: boolean
  username?: string
  customerName?: string
  login: (credentials: Credentials) => Promise<boolean>
  logout: () => Promise<boolean>
  getLicenses: (appCode: string) => string[]
  getLoginUrl: (websiteUrl: string | undefined, licenseId: string) => string
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
  isLoggedIn: false,
  licenses: [],
  loadingLogin: false,
  login: (_credentials: Credentials) => Promise.resolve(true),
  logout: () => Promise.resolve(true),
  getLicenses: (_appCode) => [],
  getLoginUrl: (_websiteUrl, _licenseId) => '',
})

export default function AuthenticationProvider(props: { children: ReactNode }) {
  // User
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [username, setUsername] = useState<string | undefined>(undefined)
  const [customerName, setCustomerName] = useState<string | undefined>(undefined)
  const [credentials, setCredentials] = useState<Credentials | undefined>(undefined)
  const [loadingLogin, setLoadingLogin] = useState<boolean>(false)

  //licenses
  const [licenses, setLicenses] = useState<LicenseDetails[]>([])

  //Alert
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('success')
  const { t } = useTranslation()
  const navigate = useNavigate()

  // get credentials from localStorage and log in if there are any, otherwise navigate to login screen
  useEffect(() => {
    setLoadingLogin(true)
    const storedCredentials = getLocalStorageCredentials()
    if (storedCredentials) {
      login(storedCredentials)
        .then((_result) => navigate('/home'))
        .finally(() => setLoadingLogin(false))
    } else {
      navigate('/login')
      setLoadingLogin(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //login and logout functions -----------------------------------------------------------------------------------

  /**
   * logs the user in with the given credentials and stores the data in the context and localStorage if successful
   * @param credentials the credentials to log in with
   * @returns true if the login was successful, false otherwise
   */
  async function login(credentials: Credentials): Promise<boolean> {
    return new Promise((resolve, reject) => {
      api
        .getAppLicenses(credentials, i18next.language)
        .then((result) => {
          //set values
          setCredentials(credentials)
          setUsername(credentials.username)
          setCustomerName(result.customerName)
          setIsLoggedIn(true)
          setLicenses(result.customerAppLicenseDetails)

          // save the credentials to localStorage
          saveLocalStorageCredentials(credentials)

          //create alert message
          setAlertMessage(Utils.createHelloMessage(credentials.username, t))
          setAlertSeverity('success')
          setIsAlertOpen(true)

          // resolve
          resolve(true)
        })
        .catch((error) => {
          console.error('Error while logging in:', error.message)

          //create alert message
          setAlertMessage(t('LOGIN_ERROR'))
          setAlertSeverity('error')
          setIsAlertOpen(true)

          reject(error)
        })
    })
  }

  /**
   * logs the user out and resets all authentication data
   */
  async function logout(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      resetAuthenticationData()
      deleteLocalStorageCredentials()
      setIsLoggedIn(false)
      resolve(true)
    })
  }

  //license functions-----------------------------------------------------------------------------------
  /**
   * returns all licenses for the given appCode
   * @param appCode the appCode to get the licenses for
   * @returns the licenses for the given appCode
   */
  function getLicenses(appCode: string) {
    return licenses.filter((lic) => lic.softwareCode === appCode).map((lic) => lic.appLicenseId)
  }

  /**
   * creates a login url for the website with the given license id and current user credentials
   * @param websiteUrl the base url of the website
   * @param licenseId the license id to login with
   * @returns the final login url
   */
  function getLoginUrl(websiteUrl: string | undefined, licenseId: string) {
    //create loginKey with licenseId and credentials in plain text
    const loginKey = `license=${licenseId}&username=${credentials?.username}&password=${
      credentials?.password ?? ''
    }`
    //return the login url with the loginKey base64 encoded
    return `${websiteUrl}login?key=${btoa(loginKey)}`
  }

  //helping functions for local storage of credentials ---------------------------------------------------

  /**
   * saves the given credentials in localStorage
   * @param credentials the credentials to save in localStorage
   */
  function saveLocalStorageCredentials(credentials: Credentials) {
    const stringify = JSON.stringify(credentials)
    const base64 = btoa(stringify)
    localStorage.setItem(LocalStorageKeys.credentials, base64)
  }

  /**
   * gets the credentials from localStorage and sets them in the context
   * @returns the credentials from localStorage
   */
  function getLocalStorageCredentials(): Credentials | undefined {
    const storedData = localStorage.getItem(LocalStorageKeys.credentials)
    if (storedData) {
      const stringifiedCredentials = atob(storedData)
      const credentials = JSON.parse(stringifiedCredentials)
      setCredentials(credentials)
      return credentials
    }
    return undefined
  }

  /**
   * deletes the credentials from localStorage
   */
  function deleteLocalStorageCredentials() {
    localStorage.removeItem(LocalStorageKeys.credentials)
  }

  /**
   * resets all authentication data
   */
  function resetAuthenticationData() {
    setCustomerName(undefined)
    setCredentials(undefined)
    setUsername(undefined)
    setLicenses([])
  }

  return (
    <AuthenticationContext.Provider
      value={{
        isLoggedIn,
        licenses,
        loadingLogin,
        login,
        logout,
        username,
        customerName,
        getLicenses,
        getLoginUrl,
      }}>
      {props.children}
      <AlertSnackbar
        severity={alertSeverity}
        open={isAlertOpen}
        setOpen={setIsAlertOpen}
        message={alertMessage}
      />
    </AuthenticationContext.Provider>
  )
}

export function useAuthentication() {
  const context = useContext(AuthenticationContext)

  if (context === undefined)
    throw new Error('useAuthentication can only be used inside AuthenticationProvider')
  return context
}
